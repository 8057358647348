import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
    InputNumber,
    Radio,
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Content } = Layout;

const InactiveOperator = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [operatorList, setOperatorList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)
    const supplierNames = "ABHI,VRLT,YLBS,ZGBS,ITSG,ETST,TRYR,EZIF,BTLA,REDB,IRCT";

    useEffect(() => {
        // getOperatorList();
        // getOperatorList({ supplier: supplierNames });
        getOperatorList();
    }, []);
    // const onFormSubmit = (val) => {

    //     let InOperator = {
    //         filter: {
               

    //         },
    //         page: 0
    //     }

    //     getOperatorList(InOperator);
    // };
    const getOperatorList = () => {
        // let page = { supplierName: value?.supplier };
        ApiClient.get("operators/getallinactiveoperator")
            .then((res) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (res == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (res.isSuccess) {
                    // let data = res.results.map((item, index) => {
                    //     return {
                    //         SNo: index + 1,
                    //         ...item,
                    //     };
                    // });
                    let data = res?.data;
                    setOperatorList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const add = (val) => {
        ApiClient.post("operators/postinactiveoperator", val)
            .then((response) => {
                return response;
            })
            .then((response) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (response == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (response.isSuccess) {
                    setModalVisible(false);
                    message.success("Successfully added operator", 3);
                    // getOperatorList();
                    getOperatorList({ supplier: supplierNames });
                    form.resetFields();
                } else if (!response.isSuccess) {
                    message.error("Operators Already existed", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const deleteOperator = (Record) => {
        // console.log(Record, "rec");
        let Id = Record.index;
        // let obj = { supplierName: Record.supplierName, id: Record.id, index: Record.BTLARecord.index, }
        ApiClient.delete(`operators/deleteinactiveoperator?index=${Id}`)
            .then((res) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (res == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (res.isSuccess) {
                    message.success("Operators Deleted  successfully", 3);
                    // getOperatorList();
                    getOperatorList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const updateOperator = (val) => {
        ApiClient.put(`operators/updateoperator/${currentId.id}`, {}, val).then(
            (resp) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (resp == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (resp.isSuccess) {
                    // getOperatorList();
                    getOperatorList({ supplier: supplierNames });
                    setModalVisible(false);
                    message.success("Operators Updated successfully", 3);
                    setCurrentId(-1);
                }
            }
        );
    };

    const submitForm = (val) => {
        let obj = {
            ...val,
        };
        if (showUpdateBtn) updateOperator(obj);
        else add(obj);
    };


    const editCurrentItem = (id, data) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            ...data,
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const searchData = () => {
        setShowSearchBox(true);
    };

    const tableAction = (currentRecord, Name) => {
        // console.log(currentRecord, "current", Name);
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {/* <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord, Name)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div> */}
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteOperator(currentRecord)}
                    >
                        <span>
                            <DeleteOutlined
                                style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                            /> Delete </span>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "Supplier ID",
            dataIndex: "supplierId",
            key: "supplierId",
        },
        {
            title: "Supplier Name",
            dataIndex: "supplierName",
            key: "supplierName",
        },
        {
            title: "Inactive Operators",
            dataIndex: "operatorName",
            // dataIndex: "inActiveOperator",
            // key: "inActiveOperator",
            // render: (operators) =>
            //     operators.map((operator, index) => (
            //         <div key={index} >{operator.operatorName}</div>
            //     )), // Render multiple operators dynamically

        },
        {
            title: "Actions",
            render: (rowRecords) => tableAction(rowRecords)
        },
    ];

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(operatorList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(operatorList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        // console.log("comming-1", tableList);
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            Operator: item.name,
            ServiceType: item.serviceType,
        }));

        exportExcel(excelData, "Operators");
    };

    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">

                    <div className="card-bt-gap">
                        <Card bordered={false} style={{ borderRadius: "25px" }}>
                            <Row className="card-add-heading">
                                {/* <Col span={24}>
                                    <div className="card-bt-gap">
                                        <h4> TopBus Operators</h4>

                                        <Form
                                            form={form}
                                            layout={"vertical"}
                                            onFinish={onFormSubmit}
                                        >
                                            <Row gutter={12} align="middle">
                                                
                                               

                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Search Type"
                                                        name="searchType"
                                                    // rules={[
                                                    //   { required: true, message: "Please enter To Date" },
                                                    // ]}
                                                    >
                                                        <Select placeholder="Please select Status" onChange={handleTimeAndDate} defaultValue="Lasthour">
                                                            <Option value="1">Lasthour </Option>
                                                            <Option value="2">Today </Option>
                                                            <Option value="3">Yesterday </Option>
                                                            <Option value="4">Lastweek </Option>
                                                            <Option value="5">CustomDates  </Option>

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {isDateDisplay ? (<>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="From Date"
                                                            name="fromDate"
                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                placeholder="From Date"
                                                                onChange={(date, dateString) =>
                                                                    onChangeFromDate(date, dateString)
                                                                }
                                                                disabledDate={disabledFromDate}

                                                                //   disabledDate={disaleFromDate}
                                                                // onChange={(date, dateString) =>
                                                                //     onChangeFromDate(date, dateString)
                                                                // }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="To Date"
                                                            name="toDate"

                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                disabledDate={disabledToDate}
                                                                onChange={(date, dateString) =>
                                                                    onChangeToDate(date, dateString)

                                                                }
                                                                // disabledDate={disaleToDate}
                                                                placeholder="To Date"
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>) : null}
                                                {user.role.toUpperCase() === "ADMIN"
                                                    && (
                                                        <Col md={4} sm={12} xs={24}>
                                                            <Form.Item name="api" label="Supplier">
                                                                <Select placeholder="Service Name">
                                                                    {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    )}
                                                {/* <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Result Type"
                                                        name="showType"
                                                    >
                                                        <Select
                                                            placeholder="Select"
                                                            onSelect={(val) => setReportRole(val)}
                                                        >
                                                            <Option value="Consolidate">Consolidate</Option>
                                                            <Option value="Distinct">Distinct </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> *


                                                <Col md={4} sm={12} xs={24}  >
                                                    <Button
                                                        key="add"
                                                        className="site-admin-btn-11"
                                                        type="primary"
                                                        onClick={form.submit}
                                                        // style={{ marginBottom: "4%" }}
                                                        style={{ marginTop: "30px" }}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col> */}
                                <Col span={24}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <h5>View Inactive Operators</h5>
                                            <p>{tableList.length} rows found !</p>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    autocomplete="newpassword"
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img src={search} alt="search" onClick={() => searchData()} />
                                            <img src={excel} alt="excel" onClick={() => handleExcel()} />
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => {
                                                    setTableList([]);
                                                    // getOperatorList();
                                                    getOperatorList({ supplier: supplierNames });
                                                }}
                                            />
                                            <p className="add-deposit-icon" onClick={handleModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    {/* {console.log(tableList, "list")} */}
                                    <Table
                                        scroll={{ x: true }}
                                        bordered
                                        rowKey={uniqueKey}
                                        dataSource={tableList}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 25,
                                            showSizeChanger: true,
                                            pageSizeOptions: ["25", "50", "100", "125"],
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Content>
            </div>
            {
                isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
            }
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update Operators" : "Add Operators"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header bci-panel-admin-parts"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row gutter={12}>
                        <Col md={12} xs={24}>
                            <Form.Item
                                label="Supplier Name"
                                name="supplierName"
                                rules={[
                                    { required: true, message: "Please enter Service Type" },
                                ]}
                            >
                                <Select placeholder="Please select Service Type">
                                    {/* <Option value="Buses">Buses</Option>
                                    <option value="NONE">NONE</option> */}
                                    <option value="IRCT">IRCT</option>
                                    <option value="REDB">REDB</option>
                                    <option value="BTLA">BTLA</option>
                                    <option value="EZIF">EZIF</option>
                                    <option value="TRYR">TRYR</option>
                                    <option value="ETST">ETST</option>
                                    <option value="ITSG">ITSG</option>
                                    <option value="VRLT">VRLT</option>
                                    <option value="ZGBS">ZGBS</option>
                                    <option value="YLBS">YLBS</option>
                                    <option value="ABHI">ABHI</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                label="Operator Name"
                                name="operatorName"
                                rules={[
                                    { required: true, message: "Please enter Operator Name" },
                                ]}
                            >
                                <Input
                                    autocomplete="newpassword"
                                    placeholder="Please Enter Operator Name"
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col md={12} xs={24}>
                            <Form.Item label="RTC" name="isRtc">
                                <Radio.Group>
                                    <Radio value={true}>True</Radio>
                                    <Radio value={false}>False</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col> */}


                        {/* <Col md={12} xs={24}>
                            <Form.Item label="Active" name="isActive">
                                <Radio.Group>
                                    <Radio value={true}>True</Radio>
                                    <Radio value={false}>False</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col> */}

                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default InactiveOperator;
