import React from 'react'
import { Layout, Card, Table, Spin, Input, Form, message, DatePicker, Modal, Col, Row, Popconfirm, Button, Collapse, Select } from 'antd'
import { PlusCircleFilled, EditOutlined, CloseOutlined, DeleteOutlined, } from "@ant-design/icons"
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import AutoCompleteSelect from '../../common/AutoCompleteSelect/AutoCompleteSelect'
import { useState } from 'react'
import ApiClient from '../../helpers/ApiClient'
import moment from "moment";
import { useEffect } from 'react'
import "../../admin/Adminstyles.scss";
import { exportExcel } from "../ExportExcel";

const { Content } = Layout
const { Panel } = Collapse;
const { Option } = Select;

const ClientConfig = () => {



    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm()
    // const [IpLogForm] = Form.useForm()
    const [adminList, setSiteAdminList] = useState([]);
    const [UserConfigTableList, setUserConfigTableList] = useState([]);
    const [currentId, setCurrentId] = useState(-1);
    const [config, setConfig] = useState(0);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [userIdOnUpdate, setUserIdOnUpdate] = useState(-1)
    const [isDateDisplay, setIsDateDisplay] = useState(false);
    const [loadingIPs, setLoadingIPs] = useState(true);
    const [clients, setClients] = useState([]);
    const [ipFalse, setIpFalse] = useState(false);


    useEffect(() => {
        getSiteAdminList();
        getClientConfig();
        // getUserIPAddressLogs(initialUserIPLogObj);
    }, [])
    const getClientConfig = () => {
        ApiClient.get("user/getclientconfigurations")
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setLoadingIPs(false);
                    // setUserIpAdressList(data);
                    setUserConfigTableList(data);
                    setClients(data);
                } else message.error(res.message, 3);
            })
            .catch((error) => {
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });
    };




    const onSubmitForm = (FormData) => {




        const ReqObj = {
            userId: FormData.userId,
            clientConfigIds: Array.isArray(FormData.config) ? FormData.config : [FormData.config],
        };
        const UpReq = {
            userId: adminList.find((item) => item.username === FormData.userId)?.id,
            clientConfigIds: Array.isArray(FormData.config) ? FormData.config : [FormData.config],
        };
        // console.log(FormData, ReqObj, "req");
        if (showUpdateBtn) UpdateClientConfig(UpReq);
        else ClientConfig(ReqObj);
    }



    const ClientConfig = (ReqObj) => {
        ApiClient.post("user/saveclientconfigurations", ReqObj)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(res.message ?? "Client-Config Added Successfully", 3);
                    setModalVisible(false);
                    getClientConfig();
                    form.resetFields();
                } else {
                    setModalVisible(false);
                    message.error(res.message ?? "Client-Config Already existed", 3);
                }
            })
            .catch((error) => {
                setModalVisible(false);
                message.error("Failed To Add", 3);
                console.error(error);
            });
    }

    const UpdateClientConfig = (val) => {
        // console.log(val, "update");
        // val.userId = userIdOnUpdate
        ApiClient.put("user/updateclientconfigurations", {}, val)
            // ApiClient.put(`user/updateclientconfigurations/${currentId}`, {}, val)
            .then(
                (resp) => {
                    if (resp.isSuccess) {
                        getClientConfig();
                        setModalVisible(false);
                        message.success(resp.message ?? "Updated successfully", 3);
                        setCurrentId(-1);
                    } else message.error(resp.message ?? "Failed to udate Client-Config", 3)
                }
            )
            .catch((error) => {
                setModalVisible(false);
                message.error("Failed To Update", 3);
                console.error(error);
            });;
    }

    const DeleteClientConfig = (Id) => {

        ApiClient.delete("user/deleteclientconfigurations?id=" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(res.message ?? "Client-Config Deleted  successfully", 3);
                    getClientConfig();
                } else message.error(res.message ?? "Failed To Deleted Client-Config", 3)
            })
            .catch((error) => {
                console.error(error);
                message.error("Failed To Deleted Client-Config", 3)
            });
    }


    const editCurrentItem = (id, data) => {
        // console.log(id, data, "edit");
        setShowUpdateBtn(true);
        setCurrentId(id);
        setUserIdOnUpdate(data.userId)
        form.setFieldsValue({
            userId: adminList.find((item) => item.id === data.userId)?.username,
            config: data.clientConfigId, // Match config with clientConfigId

        });
        setModalVisible(true);
    };


    const closeSearch = () => {
        setShowSearchBox(false);
        // setUserConfigTableList(userIpAddressList);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const tableActions = (Id, data) => {
        // console.log(data, "tabbb");
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(Id, data)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => DeleteClientConfig(data?.userId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const searchData = () => {
        setShowSearchBox(true);
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = UserConfigTableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setUserConfigTableList(searchResults);
        } else {
            // setUserConfigTableList(userIpAddressList);
        }
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const handleExcel = (ExcelList, isfromIPLog) => {
        let excelData = ExcelList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            UserName: item.userName,
            IpAddress: item.ipAddress,
            CreatedDate: moment(item.createdOn).format("DD-MM-YYYY HH:mm"),
        }));
        let excelName = isfromIPLog ? "UserIpAddressLog Reports" : "UserIPAddressConfig Reports"
        exportExcel(excelData, excelName);
    };

    const getSiteAdminList = () => {
        let data = {
            page: 0,
        };
        ApiClient.post("user/getuserslist/list", data)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setSiteAdminList(data);
                }
            })
            .catch((e) => {
                console.log("api error", e);
            });
    };


    const handleTimeAndDate = (value) => {

        if (value === "5") {
            setIsDateDisplay(true);
        } else {
            setIsDateDisplay(false);
        }
    };

    const columns = [

        {
            title: "UserName",
            dataIndex: "userId",

            sorter: (a, b) => {
                const nameA = adminList.find((item) => item.id === a.userId)?.username || "";
                const nameB = adminList.find((item) => item.id === b.userId)?.username || "";
                return nameA.localeCompare(nameB);
            },
            render: (Id) => {

                const user = adminList.find((item) => item.id === Id);
                // console.log(user,adminList.find((item) => item.id),adminList,Id,"12345");
                return user ? user.username : "Unknown User";
            },

        },
        {
            title: "Config-Status",
            dataIndex: "clientConfigId",
            render: (clientConfigId) => {
                return clientConfigId ? "Active" : "Inactive";
            }
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "CreatedDate",
            dataIndex: "createdOn",
            render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (id, data) => tableActions(id, data),
        },
    ]

    const CheckclientId = (id) => {
        // console.log(id, "check", clients);
        if (!id || !clients) return;

        // Filter the clients object to find all records with the matching userId
        const matchedClients = clients.filter(client => client.userId === id);

        // Check if there are more than 3 entries for the client
        if (matchedClients.length >= 3) {
            setIpFalse(true);
            message.error("You cannot add more than 3 entries for this client.");
            return;
        }
        setIpFalse(false);

    }
    const handleModalClose = () => {
        setModalVisible(false);
        setIpFalse(false); // Reset ipFalse when the modal is closed
    };

    return (
        <Layout className="head-layout-check">
            <div className="manage-markup-section direction-collapse">
                <Collapse accordion defaultActiveKey={['1']} className="collapse-head-css">
                    <Panel header="Client/Ip Config" key="1">
                        <Content className="container-fluid">
                            <div className="card-bt-gap">
                                <Card bordered={false} style={{ borderRadius: "25px" }}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <h5>View Client-Config</h5>
                                            <p>{loadingIPs ? "" : UserConfigTableList.length} Rows Found !</p>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    autocomplete="newpassword"
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                                            {/* <img src={excel} alt="excel" onClick={() => handleExcel(UserConfigTableList, false)} /> */}
                                            {/* <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => {
                                                    setUserConfigTableList([]);
                                                    getClientConfig();
                                                }}
                                            /> */}
                                            <p className="add-deposit-icon" onClick={handleModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        {/* {console.log(UserConfigTableList, "table")} */}
                                        {loadingIPs ? <>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{ width: "100%" }}
                                            />
                                        </> :
                                            <Table
                                                scroll={{ x: true }}
                                                bordered
                                                rowKey={uniqueKey}
                                                dataSource={UserConfigTableList}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 25,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                }}
                                            />
                                        }
                                    </div>
                                </Card>
                            </div>
                        </Content>
                    </Panel>

                </Collapse>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update Client-Config" : "Add Client-config"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header"
                visible={modalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout='vertical' form={form} onFinish={onSubmitForm}>
                    <Row>
                        <Col className="gutter-row" md={12} xs={24}>
                            <AutoCompleteSelect
                                formItemProps={{
                                    label: "User",
                                    name: "userId",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please enter User Name",
                                        },
                                    ],
                                }}
                                selectProps={{
                                    placeholder: "Please Enter UserName",
                                }}
                                api={"user/userautosuggestion/"}
                                keytext="name"
                                keyvalue="id"
                                onChange={(value) => CheckclientId(value)}
                            />
                        </Col>
                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item name="config" label="Configuration" rules={[{ required: true, message: "Please Select any" }]}>
                                {/* <Input autoComplete='newpassword' placeholder='Enter IPAddress' disabled={ipFalse} /> */}

                                <Select
                                    placeholder="Select"
                                    onSelect={(val) => setConfig(val)}
                                >
                                    <Option value={0}>Disable</Option>
                                    <Option value={1}>Enable</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}

export default ClientConfig;