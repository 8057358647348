import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { Button, Col, Layout, Menu, Row, Modal, Input, message } from "antd";
import { TgContext } from "../common/providers/TgProvider";
import { AdminAccessContext } from "../common/providers/AdminAccessProvider";
import { Link, useHistory } from "react-router-dom";
import i2space from "../assets/images/I2space.png";
import etravosLogo from "../assets/images/etravosLogo.png";
import etravosLogo1 from "../assets/images/admin-bc-icon.png";
import { Tooltip } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SnippetsOutlined,
  BarChartOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  AuditOutlined,
  UnlockOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  HddFilled,
  LineChartOutlined,
  HeatMapOutlined,
  FallOutlined,
  RiseOutlined,
  BoxPlotOutlined,
  FundOutlined,
  SlidersOutlined,
  BookOutlined,
  CarryOutOutlined,
  CommentOutlined,
  ExceptionOutlined,
  SyncOutlined
} from "@ant-design/icons";
import AdminNavigations from "../common/navbar/AdminNavigations";
import "./Adminstyles.scss";
import ProtectedMenu from "../helpers/ProtectedMenu";
import moment from "moment";
import Form from "rc-field-form/es/Form";
import APIClient from "../helpers/BusApiClient";
import ApiClient from "../helpers/ApiClient";
import { useAuthContext } from "../common/providers/AuthProvider";
import SessionTimeOutModal from "../common/SessionTimeOutModal/SessionTimeOutModal";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;
const Administration = ({ updateIsLogin }) => {
  let history = useHistory();
  const { user } = useAuthContext();
  const [collapsed, setCollapsed] = useState(false);
  const [showSider, SetShowSider] = useState(false)
  const { logo } = useContext(TgContext);
  const { accessList, accessToken } = useContext(AdminAccessContext);
  const [isTimeout, setIsTimeout] = useState(false);
  const [fetchingBalance, setFetchingBalance] = useState(false);
  const [agentBalance, setAgentBalance] = useState(null)
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)
  // {console.log(accessList,"lisds")}
  const [events, setEvents] = useState([
    "click",
    "load",
    "mousemove",
    "scroll",
    "keydown",
  ]);
  const [second, setSecond] = useState(0);
  let timeout = 10;

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();


  let resetTimer = useCallback(() => {
    let token = localStorage.getItem("accessToken")
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (accessToken && token) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
      // localStorage.setItem("experiedTime", timeStamp + timeout * 1000)
      timeChecker();
    } else {
      localStorage.removeItem("lastTimeStamp");
    }

  }, [accessToken]);

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
      // console.log(storedTimeStamp, "time");
    }, 480000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    // { console.log(timeString, startTimerInterval.current, "timeString") };

    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 20; // Maximum ideal time given before logout
      const popTime = 19; // remaining time (notification) left to logout.

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
      }
      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        updateIsLogin("role", false);
        localStorage.clear();
        sessionStorage.clear();

        warning();

        history.push("/");
      }
    }, 480000);
    // { console.log("im here", warningInactiveInterval.current) }
    setIsSessionTimeOutModel(true)
  };


  const warning = () => {
    // console.log("no of time pop up",isWarningPop)

    // Modal.warning({
    //   title: "Session Expired",
    //   content: "your session is expired",
    // });
    Modal.warning({
      title: "Session Expired",
      content: "Your session has expired.",
      onOk: () => {
        // Refresh the page or redirect as needed
        window.location.reload();
      },
    });



  };

  useEffect(() => {
    if (accessToken) {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
      timeChecker();

      return () => {
        clearTimeout(startTimerInterval.current);
      };
    }
  }, [resetTimer, events, timeChecker]);


  useEffect(() => {
    getScreensList();
    // getCategoryList();
    getUserbalance()
  }, []);
  // const startInterval = () => {
  //   updateExpireTime
  //   const interval = setInterval(() => {
  //     const experiedTime = parseInt(localStorage.getItem("experiedTime"), 10)
  //     if (experiedTime < Date.now()) {
  //       console.log("timeoutt")
  //     }
  //   }, 1000)
  // }
  const [screensList, setScreensList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);


  // const getScreensList = () => {
  //   let obj = {
  //     filter: {
  //       id: user ? user.roleId : null,
  //     },
  //     page: 0,
  //   };
  //   ApiClient.post("codes/getscreens/list", obj)
  //     .then((res) => {
  //       if (res.isSuccess) {
  //         // let data = res.results.map((item, index) => {
  //         //   return {
  //         //     SNo: index + 1,
  //         //     ...item,
  //         //   };
  //         // });
  //        
  //         // setScreensList(data);
  //         setScreensList(screenArray);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const getScreensList = () => {
    ApiClient.get("codes/getusermappedscreens")
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }

        if (res.isSuccess) {

          let screenArray = [], categories = []
          res.data.forEach(item => {
            let categoryObj = {
              isActive: true,
              name: item.catageory
            }
            categories.push(categoryObj)
            const screens = item.screens.map(screen => {
              return {
                ...screen,
                isActive: true,
                categoryName: item.catageory
              }
            })
            screenArray.push(...screens)
          })

          setCategoryList(categories);
          setScreensList(screenArray);

        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const getCategoryList = () => {
    ApiClient.get("codes/getcategories")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setCategoryList(data.reverse());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AdminLogout = () => {
    updateIsLogin("flag", false);
    updateIsLogin("role", false);
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  };
  const menuToggle = () => {
    //setCollapsed((prev) => !prev);
    SetShowSider((prev) => !prev);
  };

  const BASE = process.env.REACT_APP_BASE_URL;
  const onSearch = (value) => {
    getTicketDetails(value);
  };
  const getTicketDetails = (ReferenceNumber) => {

    history.push(`/admin/bus_ticket?tktNo=${ReferenceNumber}`);

    // let obj = {
    //   tktNo: tktNo,
    // };
    // APIClient.post("bus/ticket", obj)
    //   .then((res) => {
    //     if (res && res?.error) {
    //       message.error(res.error.desc, 3);
    //     } else {
    //       history.push(`/admin/bus_ticket?tktNo=${tktNo}`);
    //     }
    //   })
    //   .catch((error) => {
    //     message.error("Unauthorized", 3);
    //     console.error(error);
    //   });

    // APIClient.get(`booking/busbookingdetails/${ReferenceNumber}`)
    //   .then((res) => {
    //     if (res && res?.isSuccess==false) {
    //       //message.error(res.error.desc, 3);
    //       history.push(`/admin/bus_ticket?tktNo=${ReferenceNumber}`);

    //     } else {
    //      // history.push(`/admin/bus_ticket?tktNo=${ReferenceNumber}`);
    //     }
    //   })
    //   .catch((error) => {
    //     message.error("Unauthorized", 3);
    //     console.error(error);
    //   });
  };
  const getIconFromName = (name) => {
    switch (name) {
      case "Master":
        return <AuditOutlined className="hover-css" />;
      case "Mains":
        return <SnippetsOutlined className="hover-css" />;
      case "Mapping":
        return <UnlockOutlined className="hover-css" />;
      case "Users":
        return <FilePdfOutlined className="hover-css" />;
      case "BalanceUpdate":
        return <SettingOutlined className="hover-css" />;
      case "Commissions":
        return <BarChartOutlined className="hover-css" />;
      case "API Guide":
        return <TeamOutlined className="hover-css" />;
      case "Change Password":
        return <LineChartOutlined className="hover-css" />;
      case "API Config":
        return <EnvironmentOutlined className="hover-css" />;
      case "Orders":
        return <HeatMapOutlined className="hover-css" />;
      case "Buses":
        return <FallOutlined className="hover-css" />;
      case "Statements":
        return <RiseOutlined className="hover-css" />;
      case "Manage Balance":
        return <SettingOutlined className="hover-css" />;
      case "Suppliers":
        return <BoxPlotOutlined className="hover-css" />;
      case "Operators":
        return <FundOutlined className="hover-css" />;
      case "Roles":
        return <SlidersOutlined className="hover-css" />;
      case "Memberships":
        return <BookOutlined className="hover-css" />;
      case "Category":
        return <CarryOutOutlined className="hover-css" />;
      case "Screens":
        return <CommentOutlined className="hover-css" />;
      case "Admin":
        return <CommentOutlined className="hover-css" />;
      // case 34:
      //   return <DashboardOutlined className="hover-css" />;
      // case 34:
      //   return <FileSearchOutlined className="hover-css" />;
      // case 34:
      //   return <HddFilled className="hover-css" />;
      default:
        return <ExceptionOutlined className="hover-css" />;
    }
  };
  const getUserbalance = () => {

    if (user?.role?.toUpperCase() == "SITEADMIN") {
      setFetchingBalance(true);
      ApiClient.get("user/balance")
        .then((res) => {
          /** res will be return undefined if the API is UnAuthorize   */
          if (res == "TypeError: Failed to fetch") {
            setIsSessionTimeOutModel(true)
          }
          if (res.isSuccess) {
            setAgentBalance(res.data);
            setFetchingBalance(false);
          }
        })
        .catch((error) => {
          setFetchingBalance(false);
          console.error(error);
        });
    }

  }

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div id="admin">
      <Layout >
        {showSider ? (
          <Sider
            style={{ minHeight: "100vh" }}
            width={"160px"}
            trigger={null}
            collapsible
            // collapsed={width < 100 ? collapsed : !collapsed}
            defaultCollapsed={false}
            className="site-layout-background"
          >
            <div className="logo">
              {/* {logo ? (
                <img src={BASE + logo.substring(1)} alt="BCI_Logo" />
              ) : (
                <img src={etravosLogo} alt="BCI_Logo" />
              )} */}
            </div>

            <Menu theme="dark" mode="inline" className="side-bar-scss">

              {/* {user ? (
              user.roleId === 1 ? (
                // <Menu.Item key="/admin/dashboard" icon={<DashboardOutlined />}>
                //   <Link to="/admin/dashboard">Dashboard </Link>
                // </Menu.Item>
                ""
              ) : (
                <ProtectedMenu accessList={accessList} key="/admin/busbooking">
                  <Link to="/admin/busbooking">Bus</Link>
                </ProtectedMenu>
              )
            ) : null} */}
              {/* {console.log(categoryList,screensList, "category")} */}
              {categoryList.length > 0
                ? categoryList.map((category, index) => (
                  <SubMenu

                    key={`sub+${index}`}
                    icon={getIconFromName(category.name)}
                    title={category.name}
                  >
                    {screensList.length > 0
                      ? screensList
                        .filter((d) => d.categoryName === category.name)
                        .map((screens) => (
                          <ProtectedMenu
                            accessList={accessList}
                            key={screens.path}
                            icon={getIconFromName(screens.name)}

                          >
                            <Link onClick={menuToggle} to={screens.path}>{screens.name}</Link>
                          </ProtectedMenu>
                        ))
                      : null}
                  </SubMenu>
                ))
                : null}

              {/* {user ? (
                user.roleId === 2 ? (
                  <SubMenu key="sub1" icon={<AuditOutlined />} title="Main">
                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/siteadmins-deposit"
                    >
                      <Link to="/admin/siteadmins-deposit"> Deposits</Link>
                    </ProtectedMenu>
                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/siteadmins-statement"
                    >
                      <Link to="/admin/siteadmins-statement">Statements</Link>
                    </ProtectedMenu>
                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/buscommissions"
                    >
                      <Link to="/admin/buscommissions"> Commissions</Link>
                    </ProtectedMenu>
                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/apireference"
                    >
                      <Link to="/admin/apireference">API Reference</Link>
                    </ProtectedMenu>

                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/bookingreports"
                    >
                      <Link to="/admin/bookingreports">Booking Reports</Link>
                    </ProtectedMenu>
                    <ProtectedMenu
                      accessList={accessList}
                      key="/admin/changepassword"
                    >
                      <Link to="/admin/changepassword">Change Password</Link>
                    </ProtectedMenu>
                  </SubMenu>
                ) : null
              ) : null} */}


              {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Masters">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/role"
                icon={<UserOutlined />}
              >
                <Link to="/admin/role">Roles</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/membership"
                icon={<UserOutlined />}
              >
                <Link to="/admin/membership">Membership</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/siteadmin"
                icon={<TeamOutlined />}
              >
                <Link to="/admin/siteadmin">Site Admin</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/category"
                icon={<TeamOutlined />}
              >
                <Link to="/admin/category">Category</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/screens"
                icon={<TeamOutlined />}
              >
                <Link to="/admin/screens">Screens</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/screenmapping"
                icon={<TeamOutlined />}
              >
                <Link to="/admin/screenmapping">Screen Mapping</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub2" icon={<AuditOutlined />} title="Commissions">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/buscommissions"
              >
                <Link to="/admin/buscommissions">Bus Commissions</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub3" icon={<FileTextOutlined />} title="Operators">
              <ProtectedMenu accessList={accessList} key="/admin/operators">
                <Link to="/admin/operators">Operators</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub4" icon={<UnlockOutlined />} title="Suppliers">
              <ProtectedMenu accessList={accessList} key="/admin/suppliers">
                <Link to="/admin/suppliers">Suppliers</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub5" icon={<HddFilled />} title="Deposits">
              <ProtectedMenu accessList={accessList} key="/admin/reports">
                <Link to="/admin/ ">Manage Balance </Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/statements">
                <Link to="/admin/statements">Statements</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub6" icon={<AuditOutlined />} title="Booking">
              <ProtectedMenu accessList={accessList} key="/admin/busbooking">
                <Link to="/admin/busbooking">Bus</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu
              key="sub7"
              icon={<FileSearchOutlined />}
              title="Site Admin Screens"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/siteadmins-deposit"
                // icon={
                //   <>
                //     <i className="fa fa-calendar"></i>
                //   </>
                // }
              >
                <Link to="/admin/siteadmins-deposit">Site Admin Deposits</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/siteadmins-statement"
              >
                <Link to="/admin/siteadmins-statement">
                  Site Admin Statements
                </Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/siteadmins-quickhelp"
              >
                <Link to="/admin/siteadmins-quickhelp">
                  Site Admin Quick Help
                </Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu
              key="sub8"
              icon={<FileSearchOutlined />}
              title="API Configue"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/apiConfigue"
                icon={
                  <>
                    <i className="fa fa-calendar"></i>
                  </>
                }
              >
                <Link to="/admin/apiConfigue">API Configue</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu
              key="sub9"
              icon={<FileSearchOutlined />}
              title="Booking Reports"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/bookingreports"
                icon={
                  <>
                    <i className="fa fa-calendar"></i>
                  </>
                }
              >
                <Link to="/admin/bookingreports">Booking Reports</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu
              key="sub10"
              icon={<FileSearchOutlined />}
              title="API Reference"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/apireference"
                icon={
                  <>
                    <i className="fa fa-calendar"></i>
                  </>
                }
              >
                <Link to="/admin/apireference">API Reference</Link>
              </ProtectedMenu>
            </SubMenu> */}
              {/* <SubMenu key="sub2" icon={<AuditOutlined />} title="Markups">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/flightmarkup"
                icon={
                  <>
                    <i className="fa fa-plane"></i>
                  </>
                }
              >
                <Link to="/admin/flightmarkup">Flight Markup</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/busmarkup"
                icon={
                  <>
                    <i className="fa fa-bus"></i>
                  </>
                }
              >
                <Link to="/admin/busmarkup">Bus Markup</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub4" icon={<FilePdfOutlined />} title="Ticketing">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/travelCalendar"
                icon={
                  <>
                    <i className="fa fa-calendar"></i>
                  </>
                }
              >
                <Link to="/admin/travelCalendar">Travel Calendar</Link>
              </ProtectedMenu>

              <Menu.Item
                key="/admin/cancelrequest"
                icon={
                  <>
                    <i className="fa fa-ban"></i>
                  </>
                }
              >
                <Link to="/admin/cancelrequest"> Cancel Requests</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" icon={<FileSearchOutlined />} title="Reports">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/reports"
                icon={<FileTextOutlined />}
              >
                <Link to="/admin/reports">All Reports</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/contactforms"
                icon={
                  <>
                    <i className="fa fa-list"></i>
                  </>
                }
              >
                <Link to="/admin/contactforms">Contact Forms</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/flightenquiries"
                icon={
                  <>
                    <i className="fa fa-list"></i>
                  </>
                }
              >
                <Link to="/admin/flightenquiries">Flight Enquiries</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/subscriberreport"
                icon={
                  <>
                    <i className="fa fa-users"></i>
                  </>
                }
              >
                <Link to="/admin/subscriberreport">Subscriber Report</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub6" icon={<UserOutlined />} title="Logs">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/pglogs"
                icon={
                  <>
                    <i className="fa fa-credit-card-alt"></i>
                  </>
                }
              >
                <Link to="/admin/pglogs">PG Log</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/apilogs"
                icon={
                  <>
                    <i className="fa fa-link"></i>
                  </>
                }
              >
                <Link to="/admin/apilogs">API Log</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub7" icon={<EnvironmentOutlined />} title="Mapping">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/createlocation"
                icon={<EnvironmentOutlined />}
              >
                <Link to="/admin/createlocation">Create Location</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/updatelocation"
                icon={<EnvironmentOutlined />}
              >
                <Link to="/admin/updatelocation">Update Location</Link>
              </ProtectedMenu>
            </SubMenu> */}

              {/* <ProtectedMenu
              accessList={accessList}
              key="/admin/settings"
              icon={<SettingOutlined />}
              className="individual-menu"
            >
              <Link to="/admin/settings">Settings</Link>
            </ProtectedMenu> */}
            </Menu>
          </Sider>
        ) : null}
        <Layout>
          <Header className="site-layout-background headerColor">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: menuToggle,
              }
            )}
            <div className="logo1">
              <Link to="/">
                {logo ? (
                  <img src={BASE + logo.substring(1)} alt="BCI_Logo" />
                ) : (
                  <img className="logo-view" src={etravosLogo} alt="BCI_Logo" />
                  // <img className="logo-view1" src={etravosLogo1} alt="BCI_Logo" />
                )}</Link>
            </div>
            <div className="logo2">
              <Link to="/">
                {logo ? (
                  <img src={BASE + logo.substring(1)} alt="BCI_Logo" />
                ) : (
                  // <img className="logo-view" src={etravosLogo} alt="BCI_Logo" />
                  <img className="logo-view1" src={etravosLogo1} alt="BCI_Logo" />
                )}</Link>
            </div>

            <div className="user-log-menu">

              <div className="wrap-block-sec">
                {/* <Link to="/admin/busbooking">
                  <p className="mx-1 header-main-21 all-books-btn">
                    <i class="fa fa-ticket" aria-hidden="true"></i>
                  </p>
                </Link>
                <Link to="/admin/siteadmins-quickhelp">
                  <p className="mx-1 header-main-21 all-books-btn">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </p>
                </Link> */}
              </div>
            </div>
            <div className="second-log-out">
              {user ? (<p className="name-admin-header">
                <b> Welcome {user.username}</b><br />
              </p>) : null}&nbsp;&nbsp;

              <div className="balance-dat">  {
                user?.role?.toUpperCase() == "SITEADMIN" && (
                  <span className="check-balance">
                    Balance : INR{" "}
                    {user && agentBalance
                      ? agentBalance?.balance
                      : "0"
                    }
                    {"  "}
                    <SyncOutlined
                      spin={fetchingBalance}
                      onClick={() => {
                        if (!fetchingBalance) getUserbalance();
                      }}
                    />
                  </span>

                )
              }</div>
              <Search
                placeholder="Bus Ticket No."
                onSearch={onSearch}
                enterButton
                style={{ width: 250 }}
                className="mx-1 header-main-21 bus-bc-tic input-style-enter"
              />
              <Link to="/admin/dashboard">
                <Tooltip title="Dashboard">
                  <p className="mx-1 header-main-21 all-books-btn">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                  </p>
                </Tooltip>
              </Link>
              <Link to="/admin/busbooking">
                <p className="mx-1 header-main-21 all-books-btn">
                  <i
                    className="fa fa-ticket"
                    aria-hidden="true"
                    title="Bus Booking"
                  ></i>
                </p>
              </Link>
              <Link to="/admin/siteadmins-quickhelp">
                <p className="mx-1 header-main-21 all-books-btn">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    title="Quick Help"
                  ></i>
                </p>
              </Link>

              {/* <Link to="/admin/bankdetails">
                <p className="mx-1 header-main-21 all-books-btn">
                  <i
                    className="fa fa-bank"
                    aria-hidden="true"
                    title="Bank Details"
                  ></i>
                </p>
              </Link> */}

              <Link to="/admin/changepassword">
                <p className="mx-1 header-main-21 all-books-btn">
                  <i
                    className="fa fa-unlock-alt"
                    aria-hidden="true"
                    title="Change Password"
                  ></i>
                </p>
              </Link>

              <Tooltip title="Logout">
                <p onClick={AdminLogout} className="mx-1 logout-admin-1">
                  <LogoutOutlined />
                </p>
              </Tooltip>

            </div>

          </Header>


          <Content>
            <AdminNavigations
              accessList={accessList}
              accessToken={accessToken}
            />
          </Content>
          <div className="footer-row" style={{ backgroundColor: "#fafafa", }}>
            <Row>
              <Col span={24}>
                {/* <h6 className="adminFooter">Copyright  2022 eTravos. All rights reserved</h6> */}
              </Col>
            </Row>
          </div>
          {
            isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
          }
        </Layout>
      </Layout>
    </div>
  );
};

export default Administration;
