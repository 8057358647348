import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
} from "antd";
import React, { useState } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";
import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";
import { downloadTicketPdf } from "../../helpers/TicketUtils";
import FlightLayout from "./Model/FlightLayout";
// import "./../../../pages/Buses/TicketDetails/BusTicketDetails.scss";
import Invoice from "./Model/UserInvoice";

const TicketSidebar = ({
  ticketData,
  ticketSearchParams,
  type,
  onCancelTicket,
}) => {
  const { user } = useAuthContext();
  const [smsForm] = Form.useForm();
  const [emailForm] = Form.useForm();

  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [emailModalVisible, setEmailemailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);
  const [chgModel, setChgModel] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailemailModalVisible((prev) => !prev);
  };

  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const loadpassengerData = (type) => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });
          else emailForm.setFieldsValue({ Email: data.Email });
        }
      });
    }
  };

  const cancelTicketApiRequest = async (ref) => {
    const data = {
      ReferenceNumber: ref,
      ServiceType: 0,
      CancellationType: 0,
      UserID: 1,
      PNR: "string",
      AirlineCharges: 0,
      ServiceCharge: 0,
      RefundAmount: 0,
      FinalRefundAmount: 0,
      RefundStatus: 0,
      CancellationCharges: 0,
      RefundType: 0,
      Remarks: "string",
      CancelledRemarks: "string",
      CancellationRemarks: "string",
      APIProviderID: 0,
      Cancelledby: 0,
      CreatedBy: 0,
      ModifiedBy: 0,
    };

    const res = await ApiClient.post("admin/airCancelRequest", data);
  };

  const [amountpayble, setAmountPayble] = useState(0);
  const [amountpaybleA, setAmountPaybleA] = useState(0);
  const [amountpaybleB, setAmountPaybleB] = useState(0);
  const OnAChange = (v) => {
    setAmountPaybleA(v.target.value);
    updateAmountPaymble();
  };
  const OnBChange = (v) => {
    setAmountPaybleB(v.target.value);
    updateAmountPaymble();
  };
  const updateAmountPaymble = () => {
    setAmountPayble(parseInt(amountpaybleA) + parseInt(amountpaybleB));
  };
  const [ResheduleForm] = Form.useForm();
  const [CancelForm] = Form.useForm();
  const submitCancelETicket = (values) => {
    let sampleRequest = {
      ReferenceNumber: "string",
      ServiceType: 0,
      CancellationType: 0,
      UserID: 0,
      PNR: "string",
      AirlineCharges: 0,
      ServiceCharge: 0,
      RefundAmount: 0,
      FinalRefundAmount: 0,
      RefundStatus: 0,
      CancellationCharges: 0,
      RefundType: 0,
      Remarks: "string",
      CancelledRemarks: "string",
      CancellationRemarks: "string",
      APIProviderID: 0,
      CancelledBy: 0,
      CreatedBy: 0,
      ModifiedBy: 0,
    };

    const requestData = {
      ...sampleRequest,
      ...values,
      ReferenceNumber: ticketSearchParams.ref,
    };

    ApiClient.post("admin/airCancelRequest", requestData).then((res) => {
      if ((res.status = 200)) {
        message.success("Cancel Request Processed");
      } else {
        message.error("Something Went Wrong");
      }
    });

    setCancelModel(false);
  };
  const submitResheduleTicket = (values) => {
    let sampleRequest = {
      UserID: 0,
      PNR: "string",
      ReferenceNumber: "string",
      RescheduleType: 0,
      RescheduleTripType: 0,
      RescheduleSource: 0,
      RescheduleDestination: 0,
      RescheduleAirlineName: "string",
      RescheduleAirlineNumber: "string",
      RescheduleDepartureTime: "string",
      RescheduleArrivalTime: "string",
      RescheduleItineraryImage: "string",
      RescheduleRemarks: "string",
      RescheduleAirlineCharges: 0,
      RescheduleServiceCharge: 0,
      RescheduleRefundAmount: 0,
      RescheduleFinalRefundAmount: 0,
      RefundStatus: 0,
      RescheduleCharges: 0,
      RescheduleAdminRemarks: "string",
      CreatedBy: 0,
      ModifiedBy: 0,
    };
    const requestData = {
      ...sampleRequest,
      ...values,
      ReferenceNumber: ticketSearchParams.ref,
    };
    requestData.ResheduleDate = values.ResheduleDate.format("YYYY-MM-DD");

    ApiClient.post("admin/airRescheduleRequest", requestData).then((res) => {
      if ((res.status = 200)) {
        message.success("Reshedule Request Processed");
      } else {
        message.error("Something Went Wrong");
      }
    });

    setChgModel(false);
  };

  const sendETicket = (val) => {
    // console.log(val);
    ApiClient.post("notification/commonUtility", val).then((res) => {
      if (res.status === 200) {
        message.success(res.message);
        if (val.trigger === 1) {
          setEmailemailModalVisible(false);
        } else {
          setSmsModalVisible(false);
        }
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  const sendFlightSMSETicket = (val) => {
    ApiClient.post("sms/AirTicketsms", val).then((res) => {
      if (res.status === 200) {
        message.success("Sms Sent Successfully");
        setSmsModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };
  const sendBusSmsETicket = (val) => {
    ApiClient.post("sms/BusTicket", val).then((res) => {
      if (res.status === 200) {
        message.success("Sms Sent Successfully");
        setSmsModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  const sendFlightETicket = (val) => {
    ApiClient.post("email/AirTicket", val).then((res) => {
      if (res.status === 200) {
        message.success("Email Sent Successfully");

        setEmailemailModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };
  const sendBusETicket = (val) => {
    // console.log(val,"sendemail");
    ApiClient.post("email/busticket", val).then((res) => {
      if (res.status === 200) {
        message.success("Email Sent Successfully");

        setEmailemailModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  const submitEmailForm = (val) => {
    // console.log(val, type, ticketData, "solu");
    if (limitCount.emailCount > 3) {
      message.error("Limit Exceeded");
      setEmailemailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }
    if (type === "Bus") {
      let formData = {
        to: val.Email,
        data: { ...ticketData },
      };
      sendBusETicket(formData);
    }
    if (type === "Flight") {
      let data = ticketData;
      if (data.cardInfo) {
        delete data.cardInfo;
      }
      let formData = {
        to: val.Email,
        data: { ...data },
      };
      sendFlightETicket(formData);
    }
  };

  const sendSms = (val) => {
    if (limitCount.smsCount > 2) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
      // console.log(limitCount);
    }
    if (type === "Bus") {
      let formData = {
        mobile: val.Mobile,
        data: { ...ticketData },
      };
      sendBusSmsETicket(formData);
    }
    if (type === "Flight") {
      let data = ticketData;
      if (data.cardInfo) {
        delete data.cardInfo;
      }
      let formData = {
        mobile: val.Mobile,
        data: { ...data },
      };
      sendFlightSMSETicket(formData);
    }
  };

  const handleCancel = (cancelTicketType) => {
    onCancelTicket(cancelTicketType);
  };
  const printTicket = () => {
    window.print();
  };

  return (
    <div className="actionable-buttons">
      <div className="ticket-options">
        <p onClick={downloadTicketPdf}>
          <DownloadOutlined /> Download E-Ticket
        </p>
      </div>

      <div className="ticket-options">
        <p onClick={() => printTicket()}>
          <PrinterOutlined /> Print E-Ticket
        </p>
      </div>

      <div className="ticket-options">
        <p onClick={() => toggleEmailModal()}>
          <MailOutlined /> Email E-Ticket
        </p>
      </div>

      {type === "Bus" ? (
        ticketData.cancellable ? (
          <div className="ticket-options">
            <p onClick={() => toggleSmsModal()}>
              <MessageOutlined /> Sms E-Ticket
            </p>
          </div>
        ) : null
      ) : ticketData.cancellable ? (
        <div className="ticket-options">
          <p onClick={() => toggleSmsModal()}>
            <MessageOutlined /> Sms E-Ticket
          </p>
        </div>
      ) : null}

      {type === "Bus" ? (
        ticketData.bookingStatus != "Cancelled" && ticketData.cancellable ? (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        ) : null
      ) : ticketData.BookingStatus !== "CANCELLED" && ticketData.cancellable ? (
        <div className="ticket-options">
          <p onClick={() => handleCancel("Flight")}>
            <FileExcelOutlined /> Cancel E-Ticket
          </p>
        </div>
      ) : null}

      <Modal
        title="User Invoice"
        visible={userInvoiceVisible}
        onOk={() => setUserinvoiceVisible(false)}
        onCancel={() => setUserinvoiceVisible(false)}
        width={"75%"}
      >
        <Invoice />
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Email E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input
 autocomplete="newpassword"  />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SMS E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={smsModalVisible}
        onOk={toggleSmsModal}
        onCancel={toggleSmsModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleSmsModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={smsForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form form={smsForm} onFinish={sendSms}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mobile No."
                name="Mobile"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Mobile Number Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword" 
                  type="number"
                  maxLength="10"
                  className="number-specing"
                  placeholder="Enter Mobile number"
                  autoComplete="off"
                  autoFocus
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Change Travel Date</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={chgModel}
        onOk={(e) => setChgModel(false)}
        onCancel={(e) => setChgModel(false)}
        width={1000}
        footer={null}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "25px" }}>
          <Col md={14} xs={24}>
            <FlightLayout
            // flightInfo={flight}
            // key={flight.flightId}
            // flightSearchData={props.flightSearchData}
            // setFlightFareRules={props.setFlightFareRules}
            />
            <div style={{ marginTop: "25px" }}>
              <Form form={ResheduleForm} onFinish={submitResheduleTicket}>
                <Form.Item
                  label="Reschedule Date:"
                  name="ReSheduleDate"
                  rules={[
                    { required: true, message: " " },
                    {
                      validator: OnlyFutureDateValidator,
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Remarks">
                  <Input
 autocomplete="newpassword"  />
                </Form.Item>
                <Divider orientation="left">
                  <h4>Admin:</h4>
                </Divider>
                <Row gutter={[16, 16]}>
                  <Col md={12} xs={24}>
                    <Form.Item label="Fare Difference:(A)" onChange={OnAChange}>
                      <Input
 autocomplete="newpassword"  />
                    </Form.Item>
                    <Form.Item
                      label="Date Change Charges:(B)"
                      onChange={OnBChange}
                    >
                      <Input
 autocomplete="newpassword"  />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <b>
                      Amount Payable: (A+B): <h6>Rs {amountpayble}</h6>
                    </b>
                    <Form.Item
                      label="Remarks"
                      name="RescheduleAdminRemarks"
                      rules={[{ required: true, message: " " }]}
                    >
                      <Input
 autocomplete="newpassword"  />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={10} xs={24}>
            <h6>
              <b>Important Information on Date Change:</b>
            </h6>

            <p>Date change can be done only for same airline</p>
            <p>
              If a customer intends to choose a different airline tham original
              on new dates, he/she will have to cancel the original ticket first
              and then book a fresh ticket
            </p>
            <p>
              Airline do not allow date change closer to departure usually
              between 0-4 hours domestic and international as per the policy.
              The time window varies as per airline.
            </p>
            <p>
              Partial Date change is not supported online, please call us or
              airline for required changes
            </p>
            <p>
              Any seat, meals, baggage, purchased on an old ticket will no
              longer be applicable on an amended flight. The Customer will have
              to rebook the ancillary services.
            </p>
            {/* <div className="choose-btn-wrapper">
              <FlightSelection
                flightInfo={flight}
                changeFlightResultsView={props.changeFlightResultsView}
                flightSearchData={props.flightSearchData}
              />
            </div> */}
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Cancel E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={cancelModel}
        onOk={(e) => setCancelModel(false)}
        onCancel={(e) => setCancelModel(false)}
        width={1000}
        footer={null}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "25px" }}>
          <Col md={14} xs={24}>
            <FlightLayout
            // flightInfo={flight}
            // key={flight.flightId}
            // flightSearchData={props.flightSearchData}
            // setFlightFareRules={props.setFlightFareRules}
            />
            <div style={{ marginTop: "25px" }}>
              <Form form={CancelForm} onFinish={submitCancelETicket}>
                <Row gutter={[16, 16]}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Remarks"
                      name="CancelledRemarks"
                      rules={[{ required: true, message: " " }]}
                    >
                      <Input
 autocomplete="newpassword"  />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Button type="primary" htmlType="submit">
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default TicketSidebar;
