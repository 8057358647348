
import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form,
    Layout,
    Row,
    Select,
    Spin,
    Table,
    Input,
    message,
    Modal
} from "antd";
import {
    QuestionCircleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../common/providers/AuthProvider";
import APIClient from "./ApiClient";
// import "./Bookingreports.scss";
// import { exportExcel } from "./../ExportExcel";
import { exportExcel } from "../admin/ExportExcel";
import excel from "../admin/assets/vector-icons/excel.png";

// import APIClient from "../../helpers/BusApiClient";

import { Link, useHistory } from "react-router-dom";
import SessionTimeOutModal from "../common/SessionTimeOutModal/SessionTimeOutModal";




const { Option } = Select;
const { Content } = Layout;
export default function PaymentReportAdmin() {
    let history = useHistory();
    const [reportRole, setReportRole] = useState(0);
    const [form] = Form.useForm();
    const [tableList, setTableList] = useState([]);
    const [reportsList, setReportsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const { user } = useAuthContext();
    const [columns, setColumns] = useState([])
    const [logs, setLogs] = useState([])

    const presentDate = moment();
    const [arrivalDate, setArrivalDate] = useState(null);
    const [weekendDate, setWeekendDate] = useState(null);
    const [siteAdminList, setSiteAdminList] = useState([]);
    const [showLogsModal, setshowLogsModal] = useState(false)
    const [isDateDisplay, setIsDateDisplay] = useState(false);
    const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)
    const onChangeFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');

        return current.valueOf() > Date.now();
    }
    const disabledToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };
    const getSiteAdminList = (obj) => {
        APIClient.post("user/getuserslist/list", obj)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            // CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                            // ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
                        };
                    });
                    setSiteAdminList(data);
                    // setTableList(data);

                    // console.log(ip, obj, data, "siteadminlist");
                    // if (ip || obj.add === 1) {
                    //   setSiteAdminListN(data);
                    //   addIp(obj);
                    //   console.log("iam commingV");
                    // }

                }
            })
            .catch((e) => {
                console.log("api error", e);
            });
    };
    const getStatus = (value) => {
        if (value === 1) {
            return "Pending"
        } else if (value === 2) {
            return "Success"
        } else if (value === 3) {
            return "Failed"
        } else {
            return "N/a"
        }
    }
    const Pcolumns = [
        {
            title: "User",
            dataIndex: "createdBy",
            render: (createdBy) => {
                // Find the matching admin by ID
                const admin = siteAdminList.find((admin) => admin.id === createdBy);
                return admin ? admin.username : "Unknown"; // Display username or fallback
            },
        },
        {
            title: "Refno",
            dataIndex: "refno",
            // render: (ref, record) => getTicketDetails(ref, record),
            // sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
        },
        // {
        //     title: "Payment Reports-id",
        //     dataIndex: "paymentreportsid",

        //     // render: (text) =>
        //     //   text.bookingDate != undefined
        //     //     ? moment(text.bookingDate).format("DD-MM-YYYY")
        //     //     : "",
        //     // sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
        // },
        {
            title: "Payment Type",
            dataIndex: "paymenttype",
        },
        {
            title: "Total Amount",
            dataIndex: "totalampunt",
            sorter: (a, b) => Number(a.totalampunt) - Number(b.totalampunt),
            render: (value) => Number(value).toFixed(2),
        },
        {
            title: "Service Charge",
            dataIndex: "servicecharge",
            sorter: (a, b) => Number(a.servicecharge) - Number(b.servicecharge),
            render: (value) => Number(value).toFixed(2),
        },
        {
            title: "Transaction Charge",
            dataIndex: "transactioncharge",
            sorter: (a, b) => Number(a.transactioncharge) - Number(b.transactioncharge),
            render: (value) => Number(value).toFixed(2),
        },
        // {
        //     title: "Created By",
        //     dataIndex: "createdByDetails",
        // },


        {
            title: "Created On",
            dataIndex: "createdOn",
            sorter: (a, b) => a.createdOn?.localeCompare(b.createdOn),
            render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Modified On",
            dataIndex: "modifiedOn",
            sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
            render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
        },

        {
            title: "Status",
            dataIndex: "status",
            render: (value) => getStatus(value),
        },

    ];
    const onFormSubmit = (val) => {
        getSiteAdminList({
            page: 0, add: 1,
        });
        let obj = {
            filter: {
                fromDate: val.fromDate ?? null,
                toDate: val.toDate ?? null,
                userId: null,
                // userId: (user.role.toUpperCase() === "ADMIN" || user.role.toUpperCase() === "BUSINESSANALYST") ? val.reportsOf === 0 ? null : val.userId : user.id,
                approvalStatus: val?.approvalStatus === 1 ? "Pending" : val?.approvalStatus === 2 ? "Success" : val?.approvalStatus === 3 ? "Failed" : null,
                // searchType: val.searchType ?? "2", //"Today=2"
            },
            page: 0,
        };



        if (obj) {
            getPaymentReports(obj);

        }
    };
    const getPaymentReports = (obj) => {
        setLoading(true);
        setShowTable(true);
        setReportsList([]);
        setTableList([]);
        APIClient.post("account/getpaymentreports", obj)
            .then((res) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (res == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setReportsList(data);
                    setTableList(data);
                    // setTable(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,


        }));
        exportExcel(excelData, "Booking Reports");
    };

    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false} style={{ borderRadius: "25px" }}>
                            <Row className="card-add-heading" style={{ rowGap: "30px" }}>
                                <Col span={24}>
                                    <div className="card-bt-gap">
                                        <h5>Paymnet Reports</h5>

                                        <Form
                                            form={form}
                                            layout={"vertical"}
                                            onFinish={onFormSubmit}
                                        >
                                            <Row gutter={12} align="middle">
                                                {/* {(user.role.toUpperCase() === "ADMIN" || user.role.toUpperCase() === "BUSINESSANALYST") &&
                                                    (<Col md={5} xs={24}>
                                                        <Form.Item
                                                            label="Reports of"
                                                            name="reportsOf"
                                                        // rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select
                                                                placeholder="Select"
                                                                onSelect={(val) => setReportRole(val)}
                                                            >
                                                                <Option value={0}>Self</Option>
                                                                {/* <Option value={5}>Specific Company</Option> *
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>)} */}




                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="From Date"
                                                        name="fromDate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter From Date",
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            format="YYYY-MM-DD"
                                                            disabledDate={disabledFromDate}
                                                            onChange={(date, dateString) =>
                                                                onChangeFromDate(date, dateString)

                                                            }
                                                            placeholder="Please Enter From Date"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="To Date"
                                                        name="toDate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter To Date",
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            format="YYYY-MM-DD"
                                                            disabledDate={disabledToDate}
                                                            onChange={(date, dateString) =>
                                                                onChangeToDate(date, dateString)

                                                            }
                                                            placeholder="Please Enter To Date"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>



                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Status"
                                                        name="approvalStatus"
                                                        initialValues={{ approvalStatus: 1 }}
                                                        rules={[
                                                            { required: true, message: "Please select Status" },
                                                        ]}
                                                    >
                                                        <Select placeholder="Please select Status">
                                                            <Option value={1}>Pending </Option>
                                                            <Option value={2}>Success </Option>
                                                            <Option value={3}>Failed </Option>

                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col >
                                                    <Button
                                                        key="add"
                                                        className="site-admin-btn-11"
                                                        type="primary"
                                                        onClick={form.submit}
                                                        style={{ marginTop: "30px" }}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                                {showTable ? (
                                    <Col span={24}>
                                        {tableList.length > 0 ? (
                                            <>
                                                <div className="card-add-heading mt-30">
                                                    <div className="rows-count">
                                                        <div>
                                                            <h5>View Payment Reports {tableList.length}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="action-images">
                                                        {/* {showSearchBox && (
                                      <Input
                                        placeholder="Search"
                                        onChange={(e) => searchTableData(e)}
                                        suffix={closeSearchInput}
                                        style={{ padding: "0px 12px" }}
                                      />
                                    )}
                                    &nbsp;&nbsp;
                                    <img
                                      src={search}
                                      alt="search"
                                      onClick={(e) => searchData()}
                                    /> */}
                                                        {/* <img
                                                            src={excel}
                                                            alt="excel"
                                                            onClick={() => handleExcel()}
                                                        /> */}
                                                    </div>
                                                </div>

                                                <div className="mt-2">
                                                    <Table
                                                        scroll={{ x: true }}
                                                        bordered
                                                        dataSource={tableList}
                                                        // columns={busColumns}
                                                        columns={Pcolumns}
                                                        pagination={{
                                                            defaultPageSize: 10,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ["10", "25", "50", "100", "125"],
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : loading ? (
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <b>No Results Found!</b>
                                        )}
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                        </Card>
                    </div>
                </Content>
                {
                    isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
                }


            </div>
        </div>
    );
}

